import { createRouter, createWebHistory } from "vue-router";
import Automotive from "../views/dashboards/Automotive.vue";

import License from "../views/license/License.vue";
import Auth from "../views/authenticate/Auth.vue";
import Planos from "../views/pages/planos/Planos.vue";
import Sistemas from "../views/pages/sistemas/Sistemas.vue";
import Usuarios from "../views/pages/usuarios/Usuarios.vue";
import ModeloControladora from "../views/pages/modeloControladora/ModeloControladora.vue";
import TipoControladora from "../views/pages/tipoControladora/TipoControladora.vue";

// import CamerasIp from "../views/pages/camerasIp/CamerasIp.vue";
import Empresa from "../views/pages/empresa/Empresa.vue";
import EmpresaForm from "../views/pages/empresa/EmpresaForm.vue";
import EmpresaLicenca from "../views/pages/empresa/EmpresaLicenca.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Automotive
  },

  {
    path: "/sistemas",
    name: "Sistemas",
    component: Sistemas
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Usuarios
  },
  {
    path: "/modeloControladora",
    name: "ModeloControladora",
    component: ModeloControladora
  },
  // {
  //   path: "/camerasIp",
  //   name: "CamerasIp",
  //   component: CamerasIp
  // },
  {
    path: "/tipoControladora",
    name: "TipoControladora",
    component: TipoControladora
  },
  {
    path: "/planos",
    name: "Planos",
    component: Planos
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth
  },
  {
    path: "/license",
    name: "Licença",
    component: License
  },
  {
    path: "/empresas",
    name: "Empresa",
    component: Empresa
  },
  {
    path: "/empresa/:id?",
    name: "EmpresaForm",
    component: EmpresaForm
  },
  {
    path: "/empresa-licenca/:empresaId",
    name: "Licenças",
    component: EmpresaLicenca
  },

];
import { auth } from '@/store/auth'

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});

import { getApp } from '@/firebaseConfig.js';

router.beforeEach(async (to, from, next) => {
  const authStore = auth();
  await getApp();

  try {
    const token = await authStore.ActionCheckToken();
    if (!token && !to.fullPath.includes("auth")) {
      next("/auth")
    } else {
      if (token && to.fullPath.includes("auth")) next("/");
      next(true);
    }
  } catch (error) {
    if (to.fullPath.includes("auth")) {
      next(true)
    } else {
      next({ name: "Auth" })
    }
  }

})
export default router;
