import { AxiosResponse } from 'axios'
import { http } from '../api'

import { Usuario } from '../models'

const controller = 'usuario'



export async function GetAll(): Promise<Usuario[]> {
    const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/all`);
    return data;
}


export async function Get(id: number): Promise<Usuario> {
    const { data } = await http.get<Usuario, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: Usuario): Promise<Usuario> {
    const { data } = await http.post<Usuario, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: Usuario): Promise<Usuario> {
    const { data } = await http.put<Usuario, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<Usuario> {
    const { data } = await http.delete<Usuario, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Usuario[]> {
    const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}

export async function esqueciSenha(email: string): Promise<boolean> {
    const { data } = await http.post<boolean, AxiosResponse>(`${controller}/esqueci-senha`, email);
    return data;
}