<template>
  <nav id="navbarBlur" :class="`${!isNavFixed
    ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
    : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${darkMode ? 'bg-default' : 'bg-white'
    } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`" v-bind="$attrs" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <div class="d-flex align-items-center justify-content-between justify-content-lg-start w-100">
        <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />
        <div class="sidenav-toggler sidenav-toggler-inner" :class="isRTL ? 'me-3' : ''">
          <a href="#" class="p-0 nav-link text-body" @click.prevent="navbarMinimize">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'"></i>
              <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'"></i>
              <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'"></i>
            </div>
          </a>
        </div>
      </div>
      <div id="navbar" class="mt-2 mt-sm-0 me-md-0 me-sm-4 d-none d-lg-flex" :class="isRTL ? 'px-0' : 'me-sm-4'">
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
          <!-- <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              :placeholder="isRTL ? 'أكتب هنا...' : 'Type here...'"
            />
          </div> -->
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center me-5" style="cursor: pointer">
            <VDropdown :distance="6">
              <!-- This will be the popover reference (for the events and position) -->
              <button class="bg-transparent border-0">
                <span class="text-red text-truncate">

                  <i class="fas fa-user"></i>
                  {{ user }}
                </span>
              </button>

              <!-- This will be the content of the popover -->
              <template #popper>
                <ul class="p-3 d-flex flex-column gap-3 text-gray-400">
                  <li class="d-flex align-items-center" style="cursor: pointer">
                    <i class="fas fa-key me-3"></i>
                    <span class="d-inline" data-bs-toggle="modal" data-bs-target="#exampleModal">Alterar senha</span>
                  </li>
                  <li class="d-flex align-items-center" style="cursor: pointer" @click.stop="logout">
                    <span class="d-inline"><i class="fas fa-sign-out-alt me-3"></i>Sair</span>
                  </li>
                </ul>
              </template>
            </VDropdown>
          </li>
        </ul>
      </div>
    </div>

    <div id="navbar" class="mt-2 mt-sm-0 me-md-0 me-sm-4 ms-auto d-lg-none" :class="isRTL ? 'px-0' : 'me-sm-4'">
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
       
        </div>
        <ul class="navbar-nav justify-content-end">
     
          <li class="nav-item d-flex align-items-center me-5" style="cursor: pointer">
            <VDropdown :distance="6">
              <button class="bg-transparent border-0">
                <span class="text-red">

                  <i class="fas fa-user"></i>
                  {{ user }}
                </span>
              </button>

              <template #popper>
                <ul class="p-3 d-flex flex-column gap-3 text-gray-400">
                  <li class="d-flex align-items-center" style="cursor: pointer">
                    <i class="fas fa-key me-3"></i>
                    <span class="d-inline" data-bs-toggle="modal" data-bs-target="#exampleModal">Alterar senha</span>
                  </li>
                  <li class="d-flex align-items-center" style="cursor: pointer" @click.stop="logout">
                    <span class="d-inline"><i class="fas fa-sign-out-alt me-3"></i>Sair</span>
                  </li>
                </ul>
              </template>
            </VDropdown>
          </li>
       
        </ul>
      </div>
  </nav>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Alterar senha</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h5 class="text-center">
            Configure a nova senha
          </h5>
          <div class="form-group col-md-12">
            <label>Senha</label>
            <input class="form-control" type="password" v-model="newPassword" />
          </div>
          <div class="form-group col-md-12">
            <label>Confirmar Senha</label>
            <input class="form-control" type="password" v-model="newConfirmPassword" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white text-black" id="fechaModal" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-success" @click="alterPass">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from '../Breadcrumbs.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import { Dropdown } from 'floating-vue';
import { auth } from '@/store/auth';
import Swal from 'sweetalert2';

export default {
  name: 'Navbar',
  props: {
    nome: String
  },
  components: {
    Breadcrumbs,
    Dropdown,
  },
  data() {
    return {
      showMenu: false,
      newPassword: '',
      newConfirmPassword: '',
    };
  },
  computed: {
    ...mapState(['isRTL', 'isNavFixed', 'darkMode']),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split('/')[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    user() {
      const dados = auth();
      return dados.user.displayName;
    },
  },
  created() {
    this.minNav;
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    ...mapMutations(['navbarMinimize', 'toggleConfigurator']),
    ...mapActions(['toggleSidebarColor']),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
    logout() {
      this.$emit('logout', true);
    },
    alterPass() {

      if (this.newPassword.length < 8) {
        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Senha deve ter no minimo 8 caracteres"
        })
        return;
      }

      if (!this.senhaValida(this.newPassword)) {
        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Senha deve conter letras maiúsculas, minúsculas, números e caracteres especiais ([!|@|#|$|%|^|&|*|(|)|-|_])!"
        })
        return;
      }


      if (this.newPassword != this.newConfirmPassword) {
        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Senha e Confirmar senha estão diferentes"
        })
        return;
      }

      this.$emit('novaSenha', this.newPassword);
      this.newConfirmPassword = '';
      this.newPassword = "";
    },
    senhaValida(p) {

      var retorno = false;
      var letrasMaiusculas = /[A-Z]/;
      var letrasMinusculas = /[a-z]/;
      var numeros = /[0-9]/;
      var caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
      if (p.length < 8) {
        return retorno;
      }
      var auxMaiuscula = 0;
      var auxMinuscula = 0;
      var auxNumero = 0;
      var auxEspecial = 0;
      for (var i = 0; i < p.length; i++) {
        if (letrasMaiusculas.test(p[i]))
          auxMaiuscula++;
        else if (letrasMinusculas.test(p[i]))
          auxMinuscula++;
        else if (numeros.test(p[i]))
          auxNumero++;
        else if (caracteresEspeciais.test(p[i]))
          auxEspecial++;
      }
      if (auxMaiuscula > 0) {
        if (auxMinuscula > 0) {
          if (auxNumero > 0) {
            if (auxEspecial) {
              retorno = true;

            }
          }
        }
      }
      return retorno;
    }

  },
};
</script>

<style>
.text-gray-400 {
  color: #706f6f;
}
</style>
