<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item :to="{ name: 'Home' }" mini-icon="P" text="Home" :class="getRoute() === '/' ? 'active' : ''">
        </sidenav-item>
        <!-- <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Home"  :to="{ name: 'Smart Home' }"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
        
        </sidenav-collapse> -->
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          PÁGINAS
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-item
          :to="{ name: 'Empresa' }"
          mini-icon="E"
          text="Empresa"
          :class="getRoute() === 'Empresa' ? 'active' : ''"
        >
        </sidenav-item>
        <!-- <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Empreendimentos"
          :class="getRoute() === 'pages' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-ungroup text-warning text-sm opacity-10"></i>
          </template>
          <template #list> -->
        <!-- <ul class="nav ms-4"> -->
        <!-- nav links -->
        <!-- <sidenav-collapse-item
                refer="profileExample"
                mini-icon="P"
                text="Profile"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'Profile Overview' }"
                    mini-icon="P"
                    text="Profile Overview"
                  />
                  <sidenav-item
                    :to="{ name: 'Teams' }"
                    mini-icon="T"
                    text="Teams"
                  />
                  <sidenav-item
                    :to="{ name: 'All Projects' }"
                    mini-icon="A"
                    text="All Projects"
                  />
                </template>
              </sidenav-collapse-item>

              <sidenav-collapse-item
                refer="usersExample"
                mini-icon="U"
                text="Users"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'Reports' }"
                    mini-icon="R"
                    text="Reports"
                  />
                  <sidenav-item
                    :to="{ name: 'New User' }"
                    mini-icon="N"
                    text="New User"
                  />
                </template>
              </sidenav-collapse-item>

              <sidenav-collapse-item
                refer="accountExample"
                mini-icon="A"
                text="Account"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'Settings' }"
                    mini-icon="S"
                    text="Settings"
                  />
                  <sidenav-item
                    :to="{ name: 'Billing' }"
                    mini-icon="B"
                    text="Billing"
                  />
                  <sidenav-item
                    :to="{ name: 'Invoice' }"
                    mini-icon="I"
                    text="Invoice"
                  />
                  <sidenav-item
                    :to="{ name: 'Security' }"
                    mini-icon="S"
                    text="Security"
                  />
                </template>
              </sidenav-collapse-item>

              <sidenav-collapse-item
                refer="projectsExample"
                mini-icon="P"
                text="Projects"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'General' }"
                    mini-icon="G"
                    text="General"
                  />
                  <sidenav-item
                    :to="{ name: 'Timeline' }"
                    mini-icon="T"
                    text="Timeline"
                  />
                  <sidenav-item
                    :to="{ name: 'New Project' }"
                    mini-icon="N"
                    text="New Project"
                  />
                </template>
              </sidenav-collapse-item>

              <sidenav-item
                :to="{ name: 'Pricing Page' }"
                mini-icon="P"
                text="Pricing Page"
              />
              <sidenav-item :to="{ name: 'RTL' }" mini-icon="R" text="RTL" />
              <sidenav-item
                :to="{ name: 'Widgets' }"
                mini-icon="W"
                text="Widgets"
              />
              <sidenav-item
                :to="{ name: 'Charts' }"
                mini-icon="C"
                text="Charts"
              />
              <sidenav-item
                :to="{ name: 'Sweet Alerts' }"
                mini-icon="S"
                text="Sweet Alerts"
              />
              <sidenav-item
                :to="{ name: 'Notifications' }"
                mini-icon="N"
                text="Notifications"
              />
            </ul> -->
        <!-- </template>
        </sidenav-collapse> -->
      </li>
      <!-- <li class="nav-item">
        <sidenav-item
          :to="{ name: 'Empreendimento' }"
          mini-icon="E"
          text="Empreendimento"
          :class="getRoute() === 'empreendimento' ? 'active' : ''"
        >
        </sidenav-item>
      </li> -->
      <li class="nav-item">
        <sidenav-item
          :to="{ name: 'Planos' }"
          mini-icon="B"
          text="Planos"
          :class="getRoute() === 'Planos' ? 'active' : ''"
        >
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          :to="{ name: 'Sistemas' }"
          mini-icon="A"
          text="Sistemas"
          :class="getRoute() === 'Sistemas' ? 'active' : ''"
        >
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          :to="{ name: 'Usuarios' }"
          mini-icon="U"
          text="Usuários"
          :class="getRoute() === 'usuarios' ? 'active' : ''"
        >
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          :to="{ name: 'ModeloControladora' }"
          mini-icon="U"
          text="Modelo Controladora"
          :class="getRoute() === 'modeloControladora' ? 'active' : ''"
        >
        </sidenav-item>
      </li>
<!--    
      <li class="nav-item">
        <sidenav-item
          :to="{ name: 'CamerasIp' }"
          mini-icon="U"
          text="Câmeras ip"
          :class="getRoute() === 'CamerasIp' ? 'active' : ''"
        >
        </sidenav-item>
      </li> -->
      
      <li class="nav-item">
        <sidenav-item
          :to="{ name: 'TipoControladora' }"
          mini-icon="U"
          text="Tipo Controladora"
          :class="getRoute() === 'tipoControladora' ? 'active' : ''"
        >
        </sidenav-item>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from './SidenavItem.vue';
// import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";

export default {
  name: 'SidenavList',
  components: {
    SidenavItem,
    // SidenavCollapse,
    // SidenavCollapseItem,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split('/');
      return routeArr[1];
    },
  },
};
</script>
