import { Credential, LoginResponse, } from '../models'

import { getIdToken, signInWithEmailAndPassword, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'
import { auth } from '@/firebaseConfig.js'

export async function login(payload: Credential): Promise<LoginResponse> {
  const response = await signInWithEmailAndPassword(auth, payload.email, payload.password)
  if (response) {
    const token = await getIdToken(response.user);
    return { user: response.user, token: token };
  } else {
    throw new Error('Email ou Senha inválidos');
  }
}

export async function loginWithToken(token: string) {
  const response = await signInWithCustomToken(auth, token)
  return await getIdToken(response.user);
}

export async function onAuthChanged(): Promise<any> {
  const response = await new Promise((resolve, reject) => {
    try {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          resolve({ user: user, token: await getIdToken(user) });
        } else {
          resolve(undefined)
        }

      });
    } catch (error) {
      reject(error)
    }


  });

  return response;

}