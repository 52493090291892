import { AxiosResponse } from 'axios'
import { http } from '../api'

import { Plano } from '../models'

const controller = 'plano'



export async function GetAll(): Promise<Plano[]> {
    const { data } = await http.get<Plano[], AxiosResponse>(`${controller}/all`);
    return data;
}


export async function Get(id: number): Promise<Plano> {
    const { data } = await http.get<Plano, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: Plano): Promise<Plano> {
    const { data } = await http.post<Plano, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: Plano): Promise<Plano> {
    const { data } = await http.put<Plano, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<Plano> {
    const { data } = await http.delete<Plano, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Plano[]> {
    const { data } = await http.get<Plano[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}