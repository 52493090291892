<script  setup>
import { preloaderStore } from '@/store/preloaderStore'
import LoaderIcon from './Icons/LoaderIcon.vue'
const preloader = preloaderStore()
</script>

<template>
    <div v-if="preloader.loading" class="preloader">
        <component :is="LoaderIcon" />
    </div>
</template>

<style lang="scss" scoped>
.preloader {
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    z-index: 2549;
}

.preloader img {
    width: 100px;
}
</style>
