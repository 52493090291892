import axios from 'axios'
import { auth } from '@/store/auth';
import Swal from 'sweetalert2';

import router from '@/router'


const http = axios.create({})
if (!process.env.VUE_APP_URL) {

  axios.get(__dirname + "config.json").then(f => {
    http.defaults.baseURL = f.data.VUE_APP_URL;
    console.log(http.defaults.baseURL);
  });

  

} else {
  http.defaults.baseURL = process.env.VUE_APP_URL;
}


http.interceptors.request.use((config: any) => {
  if (config.headers === undefined) {
    return
  }
  const authStore = auth();
  if (authStore.token) {
    config.headers.Authorization = `Bearer ${authStore.token}`
  }
  return config
})

http.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const authStore = auth();
    if (!error.response.status || error.response.status == 401) {
      console.log(error.response.data.message)
      Swal
        .fire({
          icon: "warning",
          title: "Aviso",
          text: "Sua sessão foi expirada, favor se logar novamente",
        })
        .then(() => {
          authStore.ActionSingnout();
          router.push("/auth");

        });
    } else

      if (!error.response.status || error.response.status == 400) {
        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: error.response.data || "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
        });

      }
      else if (error.response.status === 500) {
        Swal.fire({
          icon: "error",
          title: "Atenção",
          text: "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
        });
      }

    return Promise.reject(error)
  }
)

export const setBearerToken = (token) => {
  if (token) {

    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete http.defaults.headers.common["Authorization"]
  }
};
export { http }