import { createApp } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import Mask from "maska";
import money from 'v-money3'
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/global.css";
import VueTilt from "vue-tilt.js";
import vSelect from 'vue-select';
import "vue-select-3/src/scss/vue-select.scss";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "vue3-treeview/dist/style.css";
import treeview from "vue3-treeview";
// import vSelect from "vue-select-3";
// import "vue-select-3/src/scss/vue-select.scss";
const options = {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    icon: true,
};

const appInstance = createApp(App);
appInstance.use(createPinia());
appInstance.use(FloatingVue, { container: '.app-container' })
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.use(money);
appInstance.use(Mask);
appInstance.component("VSelect", vSelect);
appInstance.use(Toast, options);
appInstance.component("v-select", vSelect);
appInstance.component("tree", treeview);
appInstance.mount("#app");
