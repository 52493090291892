// Import the functions you need from the SDKs you need
import { initializeApp, getApps, FirebaseApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import axios from 'axios'

let app;
let auth;
async function init() {
  return (await axios.get(window.location.origin + "/firebase.json")).data;
}

if (getApps().length) {

  app = getApps()[0];
  auth = getAuth();
} else {
  init().then(f => {
    let firebaseConfig = f;
    // Initialize Firebase


    app = initializeApp(firebaseConfig);
    //initialize firebase auth
    auth = getAuth()
  })
}

async function getApp() {
  if (!getApps().length) {
    const firebaseConfig = await init();
    app = initializeApp(firebaseConfig);
    auth = getAuth()
  } else {
    app = getApps()[0];
    auth = getAuth();

  }
}


export { app, auth, getApp }