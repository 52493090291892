import { AxiosResponse } from 'axios'
import { http } from '../api'

import { TipoControladora } from '../models'

const controller = 'tipo-Controladora'



export async function GetAll(): Promise<TipoControladora[]> {
    const { data } = await http.get<TipoControladora[], AxiosResponse>(`${controller}/all`);
    return data;
}


export async function Get(id: number): Promise<TipoControladora> {
    const { data } = await http.get<TipoControladora, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: TipoControladora): Promise<TipoControladora> {
    const { data } = await http.post<TipoControladora, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: TipoControladora): Promise<TipoControladora> {
    const { data } = await http.put<TipoControladora, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<TipoControladora> {
    const { data } = await http.delete<TipoControladora, AxiosResponse>(`${controller}/${id}`);
    return data;
}


// export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<TipoControladora[]> {
//     const { data } = await http.get<TipoControladora[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
//     return data;
// }