<template>

      <div class="mt-4 border p-2 d-md-none">
    <div v-for="(colItem, indexitem) in propsComp.items" :key="'item' + indexitem">
      <div
        v-for="(head, index) in propsComp.headers"
        :key="String('head' + index)"
        class="d-flex justify-content-between border-bottom py-2 text-sm"
      >
        <p class="text-sm text-bold m-0">{{ head.label }}</p>
        <div>
          <span v-if="head.type == 'badge'">
            <Badge :text="colItem[head.field]" :color="colItem.color" :bg-color="colItem.bgColor" />
            <!-- -->
          </span>
          <template v-else-if="head.field == 'actions'">
            <slot
              v-bind="$attrs"
              :props="{ row: colItem, index: indexitem, expandedRow: false, column: head }"
              name="actions"
            ></slot>
          </template>
          <span v-else>
            <span v-if="!hasSlotRow">{{ trazDados(colItem, head.field) }} </span>
            <slot v-bind="$attrs" :props="{ row: colItem, index: indexitem, expandedRow: false, column: head }" name="rows" v-if="hasSlotRow"></slot>
          </span>
          <div v-if="head.extra" :class="head.classExtra">
            {{ trazDados(colItem, head.fieldExtra) }}
          </div>
        </div>
      </div>
    </div>
     <div v-if="propsComp.items.length === 0">
      <div class="text-center">{{ textEmpty }}</div>
    </div>
  </div>

    <VueGoodTable :columns="propsComp.headers" :rows="propsComp.items" :search-options="{
        enabled: true,
        placeholder: 'Pesquisar'
    }" :pagination-options="{
    enabled: true,
    nextLabel: 'Próximo',
    prevLabel: 'Anterior',
    rowsPerPageLabel: 'Linhas por página',
    pageLabel: 'Página', // for 'pages' mode
    allLabel: 'Todos',
}" class="d-none d-md-block">
        <!-- "pagination" -->
        <template #table-row="props">
            <div class="row-table-">
                <span v-if="props.column.type == 'badge'">
                    <Badge :text="props.formattedRow[props.column.field]" :color="props.row.color"
                        :bg-color="props.row.bgColor" />
                    <!-- -->
                </span>
                <template v-else-if="props.column.field == 'actions'">
                    <slot v-bind="$attrs" :props="props" name="actions"></slot>
                </template>
                <span v-else>

                    <span>{{ props.formattedRow[props.column.field] }}</span>
                </span>
                <div v-if="props.column.extra" :class="props.column.classExtra">
                    {{ props.row[props.column.fieldExtra] }}
                </div>
            </div>
        </template>

        <template #emptystate v-if="!!textEmpty">
            <div class="text-center">{{ textEmpty }}</div>
        </template>
    </VueGoodTable>
</template>
  
<script setup>
import Badge from '../components/Badge.vue'
import { defineProps,  onMounted } from 'vue';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import { VueGoodTable } from 'vue-good-table-next';


const propsComp = defineProps({
    // eslint-disable-next-line vue/require-valid-default-prop
    headers: { type: Array, default: [] },
    // eslint-disable-next-line vue/require-valid-default-prop
    items: { type: Array, default: [] },
    selectOnCheckboxOnly: { type: Boolean, default: false },
    textEmpty: { type: String, default: 'Sem informações para exibição' },

})

function trazDados(item, head) {
  const heads = head.split('.');
  let dados = undefined;
  heads.map((f) => {
    if (!dados) {
      dados = item[f];
    } else {
      dados = dados[f];
    }
  });

  return dados;
}


onMounted(() => {

});


</script>
<style scoped>
.area-table {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
}

.row-table {
    display: flex;
    gap: 10px;
    padding: 15px;
}

/* .table-body .row-table:nth-child(odd) {
    background-color: #fafafa;
  } */
.area-data {
    white-space: normal;
    flex: 1;
}

.header {
    background: #f8f9fa;
    border-radius: 7px 7px 0px 0px;
    border-bottom: 1px solid #dee2e6;
}

.header p {
    font-weight: 600;
    font-size: 12px;
    color: #212529;
    padding: 0 16px;
}

.body:not(:last-child) {
    border-bottom: 1px solid #dee2e6;
}

.body p {
    font-weight: 500;
    font-size: 14px;
    padding: 0 16px;
    color: #212529;
}

.icon-area {
    position: absolute;
    top: 0;
    right: -20px;
}
</style>
  