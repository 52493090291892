import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage'

async function storeImage(file: File[] | File, folder: string): Promise<string | string[]> {
    const storage = getStorage();
    if (Array.isArray(file)) {
        const links: string[] = [];

        for (const f of file) {

            const fil = f as File;
            const storageRef = ref(
                storage,
                `${folder}/${fil.name.split(" ")[0]}_${new Date().getTime()}`
            );

            links.push(await uploadString(storageRef, await convertBase64(fil), "data_url", {
                contentType: fil.type,
            }).then(() => {
                return getDownloadURL(storageRef);
            }));
        }
        return links;

    } else {
        const storageRef = ref(
            storage,
            `${folder}/${file.name}_${new Date().getTime()}`
        );

        return await uploadString(storageRef, await convertBase64(file), "data_url", {
            contentType: file.type,
        }).then(() => {
            return getDownloadURL(storageRef);
        });
    }




};

const convertBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            if (typeof fileReader.result == "string") {
                resolve(fileReader.result);
            }
        };
    });
};

export { storeImage, convertBase64 }