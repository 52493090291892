import { defineStore } from 'pinia';
import * as storage from './storage';
import { http } from '@/core/api';
import * as AuthService from '@/core/services/AuthService';
import { esqueciSenha } from '@/core/services/UsuarioService';
import { Credential, LoginResponse } from '@/core/models';
import { auth as getAuth } from '@/firebaseConfig.js';
import { signOut } from 'firebase/auth';
interface IProps {
  user: LoginResponse;
  token: string;
}
export const auth = defineStore('auth', {
  state: () =>
    ({
      user: { nome: '' },
      token: '',
    } as IProps),
  actions: {
    ActionDoLogin(payload: Credential) {
      return AuthService.login(payload).then((res) => {
        this.ActionSetToken(res.token);
        this.user = res;
        return res;
      });
    },

    ActionGetProfile() {
      return http.post('profile').then((r) => this.ActionSetUser(r.data));
    },

    ActionForgotPassword(payload) {
      return esqueciSenha(payload).then((f) => {
        return f;
      });
    },

    ActionValidateToken(payload) {
      const url = 'validateTokenReset';
      return http.post(url, payload).then((r) => r.data);
    },

    ActionNewPassword(payload) {
      const url = 'newPassword';
      return http.post(url, payload).then((r) => r.data);
    },

    async ActionCheckToken() {
      try {
        const user = await AuthService.onAuthChanged();

        this.ActionSetToken(user.token);
        this.ActionSetUser(user.user);
        return user.token;
      } catch (error) {
        throw new Error('Token Inválido');
      }
    },
    ActionSetUser(payload) {
      this.user = payload;
    },
    ActionSetToken(payload) {
      storage.setLocalToken(payload);
      storage.setHeaderToken(payload);
      this.token = payload;
    },
    ActionSingnout() {
      signOut(getAuth);
      storage.setHeaderToken('');
      storage.deleteLocalToken();
      storage.deleteLocalUsuario();
      storage.deleteLocalPlan();
      this.ActionSetUser({});
      this.ActionSetToken('');
    },
  },
});
