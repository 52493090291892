<template>
  <section>
    <div class="container-fluid p-0 background-login">
      <img class="img-bg d-none d-lg-block" src="../../assets/img/license.webp" />
      <img class="img-bg-mobile d-block d-lg-none" src="../../assets/img/license.webp" />
      <div class="row content-overlay " :style="token && cnpj ? 'height: 100%;' : 'height: 100vh;'">
        <div class="col-12 ">
          <div class="row justify-content-center   ">
            <div class="col-12 col-lg-4 col-md-6 col-xl-8 align-self-center">
              <div class="row card-destaque">
                <div class="col-12 text-center ">
                  <h1 class="mb-3 text-white">Licença <i class="fa-solid fa-id-badge"></i></h1>
                  <h3>Para liberar acesso ao software colocar os dados abaixo</h3>
                </div>
                <div class="col-12">
                  <form role="form" class="row
                  ">
                    <div class=" col-12 " v-if="step == 0">
                      <div class="form-group">
                        <label>Token</label>
                        <input v-model="token" type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>CNPJ</label>
                        <input v-maska="'##.###.###/####-##'" v-model="cnpj" type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-12 '" v-if="step == 1">
                      <div class="form-group">
                        <h3 class="text-blue">Firebase Auth</h3>
                        <input @change="handleFile" type="file" class="form-control" ref="fileAuth" accept=".json" />
                      </div>
                      <small>Arquivo de configuração do firebase para autenticação</small>
                    </div>
                    <div class="col-12" v-if="step == 2">
                      <h3 class="text-blue">Firebase storage</h3>
                      <div class="form-group">
                        <label>API KEY</label>
                        <input class="form-control" v-model="firebase.apikey" type="text" placeholder="API KEY"
                          name="APIKEY" size="lg" />
                      </div>
                      <div class="form-group">
                        <label>Email</label>
                        <input v-model="firebase.email" type="text" placeholder="Email" name="email"
                          class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Senha</label>
                        <input v-model="firebase.password" type="text" placeholder="Senha" name="password"
                          class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Bucket</label>
                        <input v-model="firebase.bucket" type="text" placeholder="Bucket" name="Bucket"
                          class="form-control" />
                      </div>
                    </div>
                    <div class="col-12 " v-if="step == 3">
                      <h3 class="text-blue">Administrador principal</h3>
                      <div class="form-group">
                        <label>Nome</label>
                        <input class="form-control" v-model="adm.nome" type="text" placeholder="Nome" size="lg" />
                      </div>
                      <div class="form-group">
                        <label>Email</label>
                        <input v-model="adm.email" type="email" placeholder="Email" name="email" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>CPF</label>
                        <input v-maska="'###.###.###-##'" v-model="adm.cpf" type="text" placeholder="CPF" name="text"
                          class="form-control" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-center">
                        <argon-button @click.prevent="back" :disabled="step == 0 ? true : false" class="mt-4"
                          variant="gradient" color="success" full-width size="lg">Voltar
                        </argon-button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-center">
                        <argon-button v-if="step < 3" @click.prevent="steps" class="mt-4" variant="gradient"
                          color="success" full-width size="lg">Próximo
                        </argon-button>
                        <argon-button v-else @click.prevent="handleSend" class="mt-4" variant="gradient" color="success"
                          full-width size="lg">Enviar
                        </argon-button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-end me-3">
          <img class="bg-logo" src="../../assets/logoauth.png" />
        </div>
      </div>
    </div>
  </section>
  <!-- <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success" :dark-mode="true" />
      </div>
    </div>
  </div> -->
  <!-- <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h2 class="font-weight-bolder">Licença</h2>
                  <p class="mb-0">Para liberar acesso ao software colocar os dados abaixo</p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="form-group">
                      <label>Token</label>
                      <input v-model="token" type="text" class="form-control" />
                    </div>
                    <div class="form-group">
                      <label>CNPJ</label>
                      <input v-maska="'##.###.###/####-##'" v-model="cnpj" type="text" class="form-control" />
                    </div>

                    <hr class="my-3" />


                    <div class="form-group">
                      <h3>Firebase Auth</h3>
                      <input @change="handleFile" type="file" class="form-control" ref="fileAuth" accept=".json" />
                    </div>
                    <small>Arquivo de configuração do firebase para autenticação</small>

                    <hr class="my-3" />

                    <div>
                      <h3>Firebase storage</h3>
                      <div class="form-group">
                        <label>API KEY</label>
                        <input class="form-control" v-model="firebase.apikey" type="text" placeholder="API KEY"
                          name="APIKEY" size="lg" />
                      </div>
                      <div class="form-group">
                        <label>Email</label>
                        <input v-model="firebase.email" type="text" placeholder="Email" name="email"
                          class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Senha</label>
                        <input v-model="firebase.password" type="text" placeholder="Senha" name="password"
                          class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Bucket</label>
                        <input v-model="firebase.bucket" type="text" placeholder="Bucket" name="Bucket"
                          class="form-control" />
                      </div>
                    </div>
                    <hr class="my-3" />
                    <div>
                      <h3>Administrador principal</h3>
                      <div class="form-group">
                        <label>Nome</label>
                        <input class="form-control" v-model="adm.nome" type="text" placeholder="Nome" size="lg" />
                      </div>
                      <div class="form-group">
                        <label>Email</label>
                        <input v-model="adm.email" type="email" placeholder="Email" name="email" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>CPF</label>
                        <input v-maska="'###.###.###-##'" v-model="adm.cpf" type="text" placeholder="CPF" name="text"
                          class="form-control" />
                      </div>
                    </div>

                    <div class="text-center">
                      <argon-button @click.prevent="handleSend" class="mt-4" variant="gradient" color="success"
                        full-width size="lg">Enviar
                      </argon-button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
              <div
                class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden">
                <img src="../../assets/logoauth.png" />
                <span class="mask bg-gradient-danger opacity-3"></span>

                <h1 class="mt-5 text-black font-weight-bolder position-relative">
                  "ATENÇÃO"
                </h1>
                <h3 class="text-black position-relative">
                  Para utilização deste software é neccessário ter uma licensa, entre em contato com a Controle rápido
                  para adquiri-la.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main> -->
</template>
<script setup>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useStore } from "vuex";
import { onMounted, onUnmounted, ref } from "vue";

const store = useStore();
const step = ref(0);
const adm = ref({});
const cnpj = ref('');
const token = ref('');
const firebase = ref({});
const fileAuth = ref(null);

function steps() {
  step.value += 1;
}
function back() {
  step.value -= 1;
}
function handleSend() {
  console.log(firebase)
}
function handleFile() {
  console.log(fileAuth.value)
}
onMounted(() => {
  store.state.hideConfigButton = true;
  store.commit("toggleDefaultLayout");
});

onUnmounted(() => {
  store.state.hideConfigButton = false;
  store.commit("toggleDefaultLayout");
});
</script>
<style scoped>
small {
  color: #b31212;
}

label {
  color: #ffffff;
}

h3 {
  color: #ffffff;
}
.text-blue{
  color: #1a548f !important;
}

.card-destaque {
  margin-right: 10px;
  margin-left: 10px;
  padding: 20px;

  background-color: #ffffff23;
  backdrop-filter: blur(6px);
  border-radius: 20px;
}

.class-input {
  background-color: hsla(0, 0%, 100%, 0.233);
  border-color: hsla(0, 0%, 100%, 0.5);
  color: #000000;
  padding: 15px 19px;
  border-radius: 30px;
}

.class-input::placeholder {
  color: #ffffff;
}

.btn-submit {
  padding: 15px 19px;

  border-radius: 30px;
  font-weight: 400;
}

.forgot-password {
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.forgot-password:hover {
  color: #2dce89;
}

.btn-eye {
  background-color: hsla(0, 0%, 100%, 0.233);
  border-color: hsla(0, 0%, 100%, 0.5);
  padding: 15px 19px;
  border-radius: 30px;
  cursor: pointer;
}

.bg-logo {
  background-color: #ffffff;
  padding: 5px 19px;
  border-radius: 30px;
}

.bg-name {
  background-color: #ffffff83;
  padding: 5px 19px;
  border-radius: 50px;
  font-weight: 800;
  color: #000000;
}

.content-overlay {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;
  background: linear-gradient(to top, #00000086, #a13327a1);
  z-index: 1;
}

@media screen and (max-width: 576px) {
  .content-overlay {

    overflow-y: scroll;

  }

  .bg-logo {
    margin-right: 80px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.background-login {
  position: relative;
  overflow: hidden;
}

.background-login .img-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.background-login .img-bg-mobile {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
  z-index: -1;
}
</style>