import { AxiosResponse } from 'axios'
import { http } from '../api'

import { ModeloControladora } from '../models'

const controller = 'modelo-Controladora'



export async function GetAll(): Promise<ModeloControladora[]> {
    const { data } = await http.get<ModeloControladora[], AxiosResponse>(`${controller}/all`);
    return data;
}


export async function Get(id: number): Promise<ModeloControladora> {
    const { data } = await http.get<ModeloControladora, AxiosResponse>(`${controller}/${id}`);
    return data;
}

export async function GetSdk(): Promise<any[]> {
    const { data } = await http.get<any[], AxiosResponse>(`${controller}/sdk`);
    return data;
}

export async function Post(andar: ModeloControladora): Promise<ModeloControladora> {
    const { data } = await http.post<ModeloControladora, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: ModeloControladora): Promise<ModeloControladora> {
    const { data } = await http.put<ModeloControladora, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<ModeloControladora> {
    const { data } = await http.delete<ModeloControladora, AxiosResponse>(`${controller}/${id}`);
    return data;
}

export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<ModeloControladora[]> {
    const { data } = await http.get<ModeloControladora[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}